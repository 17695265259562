<template>
  <div class="container credit-container m-b-110 ps-0">
    <div class="card p-1 text-first border-0">
      <div class="card-body">
        <div class="row update-btn-div">
          <h2 class="mt-0 mt-sm-4 mb-4 fw-bold font-30">{{ $t('credit.default.pageTitle') }}</h2>
          <div class="col">
            <p v-html="$t('credit.default.detailContent')"></p>
            <div class="col mt-5 d-xl-flex d-lg-flex d-md-flex d-sm-flex m-b-100">
              <div class="jta">
                <p class="font-13 jta-title font-weight-medium" v-html="$t('credit.default.operator')"></p>
                <a href="https://www.jta-tennis.or.jp/" target="_blank"><img src="../assets/images/jta_logo_yoko_fullcolor.png" width="160"></a>
              </div>
              <div class="mx-xl-5 mx-lg-5 mx-md-5 mx-sm-5 mt-xs-3 dol">
                <p class="font-13 dol-title font-weight-medium" v-html="$t('credit.default.developer')"></p>
                <a href="https://dreamonline.co.jp/" target="_blank"><img class="my-3" src="../assets/images/dol.svg"></a>
              </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        name: "Credit",
        mounted() {
          window.scrollTo({top: 0, behavior: "auto"});
        }
    }
</script>

<style lang="scss" scoped>
  .credit-title {
    max-width: 720px;
    line-height: 30px;
  }
  .dol-title{
    margin-bottom: 9px;
  }
  .jta-title{
    margin-bottom: 9px;
  }


  @media only screen and (max-width: 1068px) {

  }
  @media only screen and (max-width: 992px) {

  }
  @media only screen and (max-width: 576px) {
    .dol{
      margin-top: 20px;
    }
    .credit-container {
      margin-top: -40px;
    }
  }

</style>